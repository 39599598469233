'use client'
import { AddIcon, ArrowBackIcon, DeleteIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Checkbox,
  HStack,
  Heading,
  IconButton,
  Input,
  Link,
  Spacer,
  Stack,
  Text,
  VStack,
} from "@chakra-ui/react";
import { Select } from 'chakra-react-select';
import * as React from 'react';
import { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import { FACTIONS_BY_SET } from '../constants/factions';

const initialPlayers = [
  { name: "", email: "" },
  { name: "", email: "" },
  { name: "", email: "" },
  { name: "", email: "" },
  { name: "", email: "" },
  { name: "", email: "" },
];

const CreateGame = () => {
  const [gameName, setGameName] = useState("");
  const [mapURL, setMapURL] = useState("");
  const [players, setPlayers] = useState(initialPlayers);
  const [usingCodexV1, setUsingCodexV1] = useState(false);
  const [usingCodexV2, setUsingCodexV2] = useState(false);
  const [usingCodexV3, setUsingCodexV3] = useState(false);
  const [usingProphecyOfKings, setUsingProphecyOfKings] = useState(false);
  const [usingDiscordantStars, setUsingDiscordantStars] = useState(false);
  const [usingUnchartedSpace, setUsingUnchartedSpace] = useState(false);
  const [limitDraftFactions, setLimitDraftFactions] = useState(false);
  const [gameNameInvalid, setGameNameInvalid] = useState(false);
  const [mapUrlInvalid, setMapUrlInvalid] = useState(false);
  const [playerNamesInvalid, setPlayerNamesInvalid] = useState([]);
  const [playerEmailsInvalid, setPlayerEmailsInvalid] = useState([]);
  const [requiredFieldError, setRequiredFieldError] = useState(false);
  const [factionSelectRequired, setFactionSelectRequired] = useState(false)
  const [factionOptions, setFactionOptions] = useState([{ value: 'baseGameFactions', label: 'Base Game Factions' }])
  const [selectedFactions, setSelectedFactions] = useState([{ value: 'baseGameFactions', label: 'Base Game Factions' }]);
  const [error, setError] = useState('')
  const navigate = useNavigate()

  useEffect(() => {
    updateFactionsList(usingProphecyOfKings, { value: 'prophecyOfKingsFactions', label: 'Prophecy Of Kings Factions' })

    if (!usingProphecyOfKings) {
      setUsingDiscordantStars(false)
      setUsingUnchartedSpace(false)
    }
  }, [usingProphecyOfKings])

  useEffect(() => {
    updateFactionsList(usingDiscordantStars, { value: 'discordantStarsFactions', label: 'Discordant Stars Factions' })

    if (usingDiscordantStars) { setUsingProphecyOfKings(true) }
  }, [usingDiscordantStars])

  useEffect(() => {
    updateFactionsList(usingCodexV3, { value: 'codexV3Faction', label: 'The Council Keleres (Codex Volume III)' })
  }, [usingCodexV3])

  useEffect(() => {
    if (usingUnchartedSpace) { setUsingProphecyOfKings(true) }
  }, [usingUnchartedSpace])

  const updateFactionsList = (usingFaction, factionInfo) => {
    if (usingFaction) {
      const addedFactionOption = [...factionOptions, factionInfo]
      setFactionOptions(addedFactionOption)

      const addedFactionSelect = [...selectedFactions, factionInfo]
      setSelectedFactions(addedFactionSelect)
    } else {
      const removedFactionOption = factionOptions.filter(option => option.value !== factionInfo.value)
      setFactionOptions(removedFactionOption)

      const removedFactionSelect = selectedFactions.filter(option => option.value !== factionInfo.value)
      setSelectedFactions(removedFactionSelect)
    }
  }

  const handleAddPlayer = () => {
    if (players.length < 8) {
      setPlayers([...players, { name: "", email: "" }]);
    }
  };

  const handleDeletePlayer = (index) => {
    if (players.length > 2) {
      const newPlayers = [...players];
      newPlayers.splice(index, 1);
      setPlayers(newPlayers);
    }
  };

  const handleCreateGame = async () => {
    const isValid = validateForm()
    if (!isValid) { return }

    const draftFactions = []
    if (limitDraftFactions && selectedFactions.length > 0) {
      selectedFactions.forEach(selection => {
        draftFactions.push(...FACTIONS_BY_SET[selection.value])
      })
    }

    const payload = {
      game_name: gameName,
      map_url: mapURL,
      players: players,
      codex_volume_1: usingCodexV1,
      codex_volume_2: usingCodexV2,
      codex_volume_3: usingCodexV3,
      prophecy_of_kings: usingProphecyOfKings,
      discordant_stars: usingDiscordantStars,
      uncharted_space: usingUnchartedSpace,
      limited_factions: draftFactions
    }
    const response = await fetch('https://api.ti4setup.markgatesman.com/game', {
      method: 'POST',
      body: JSON.stringify(payload)
    })
    .then(async res => {
      if (!res.ok) {
        const errorMessage = await res.json()
        const errorString = errorMessage.errors ?
          `${errorMessage.errors[0].title}: ${errorMessage.errors[0].detail}` :
          JSON.stringify(errorMessage)
        setError(errorString)
        return
      }

      setError('')
      return res.json()
    })
    .catch(error => console.error(error))

    if (response) {
      navigate(`/game/${response.game_code}`)
    }
  }

  const validateForm = () => {
    setGameNameInvalid(gameName === "")
    setMapUrlInvalid(mapURL === "")

    const isInvalidName = []
    const isInvalidEmail = []
    players.forEach((player, index) => {
      isInvalidName[index] = player.name === ""
      isInvalidEmail[index] = player.email === ""
    })
    setPlayerNamesInvalid(isInvalidName)
    setPlayerEmailsInvalid(isInvalidEmail)

    const factionSelectError = limitDraftFactions && selectedFactions.length === 0
    setFactionSelectRequired(factionSelectError)

    const anyFormErrors = [gameName === "", mapURL === "", ...isInvalidName, ...isInvalidEmail, factionSelectError].includes(true)
    setRequiredFieldError(anyFormErrors)
    return !anyFormErrors
  }

  const onFactionSelectChange = (value) => {
    setSelectedFactions(value)
  }

  return (
    <VStack direction="column" align="center" justify="top" height="100vh">
      <Box height={['10vh', '10vh', '15vh']} padding='2rem' textAlign='center'>
        <Heading>Create New Game</Heading>
      </Box>
      <VStack margin={[1, 1, 1]}>
        <Input
          isInvalid={gameNameInvalid}
          placeholder="Game Name/Seed"
          value={gameName}
          onChange={(e) => setGameName(e.target.value)}
          width={['xs', 'sm', 'md']}
          variant='filled'
        />
        <Input
          isInvalid={mapUrlInvalid}
          placeholder="Map URL"
          value={mapURL}
          onChange={(e) => setMapURL(e.target.value)}
          width={['xs', 'sm', 'md']}
          variant='filled'
        />
        <Spacer />
        <Text fontSize='xl' as='b' >
          Players
        </Text>
        {players.map((player, index) => (
          <HStack key={index} align="center" width={['xs', 'md', 'lg']}>
            <Input
              isInvalid={playerNamesInvalid[index]}
              placeholder="Name"
              value={player.name}
              onChange={(e) => {
                const newPlayers = [...players];
                newPlayers[index].name = e.target.value;
                setPlayers(newPlayers);
              }}
              variant='filled'
            />
            <Input
              isInvalid={playerEmailsInvalid[index]}
              placeholder="Email"
              value={player.email}
              onChange={(e) => {
                const newPlayers = [...players];
                newPlayers[index].email = e.target.value;
                setPlayers(newPlayers);
              }}
              variant='filled'
            />
            <IconButton
              aria-label="Delete player"
              icon={<DeleteIcon />}
              onClick={() => handleDeletePlayer(index)}
            />
          </HStack>
        ))}
        <Box width={['xs', 'md', 'lg']} textAlign='right'>
          <Button onClick={handleAddPlayer} leftIcon={<AddIcon />} colorScheme="linkedin">
            Add Player
          </Button>
        </Box>
        <Spacer />
        <Stack spacing={[1, 1, 5]} direction={['column', 'column', 'row']} margin={[1, 1, 5]}>
          <Checkbox value='codexV1' onChange={(e) => setUsingCodexV1(e.target.checked)}>Codex Volume I</Checkbox>
          <Checkbox value='codexV2' onChange={(e) => setUsingCodexV2(e.target.checked)}>Codex Volume II</Checkbox>
          <Checkbox value='codexV3' onChange={(e) => setUsingCodexV3(e.target.checked)}>Codex Volume III</Checkbox>

          <Checkbox
            value='prophecyOfKings'
            onChange={(e) => setUsingProphecyOfKings(e.target.checked)}
            isChecked={usingProphecyOfKings}
          >Prophecy of Kings Expansion</Checkbox>
          <Checkbox
            value='discordantStars'
            onChange={(e) => setUsingDiscordantStars(e.target.checked)}
            isChecked={usingDiscordantStars}
          >Discordant Stars Expansion</Checkbox>
          <Checkbox
            value='unchartedSpace'
            onChange={(e) => setUsingUnchartedSpace(e.target.checked)}
            isChecked={usingUnchartedSpace}
          >Uncharted Space Expansion</Checkbox>

          <Checkbox value='limitFactions' onChange={(e) => setLimitDraftFactions(e.target.checked)}>Limit Drafting Factions</Checkbox>
        </Stack>
        {limitDraftFactions && (
          <Select
            name='select-draft-factions'
            isMulti
            placeholder='Select Factions...'
            tagColorScheme='blue'
            options={factionOptions}
            size={'lg'}
            onChange={onFactionSelectChange}
            value={selectedFactions}
            isInvalid={factionSelectRequired}

          />
        )}
        <Spacer />
        <Button colorScheme="whatsapp" onClick={handleCreateGame}>Create Game</Button>
        <Spacer />
        {requiredFieldError && <Text fontSize='l' as='b' color='red' >
          Please fill out the required fields
        </Text>}
        {error && <Text fontSize='l' as='b' color='red' >
          {error}
        </Text>}
      </VStack>
      <Link href='/' color={'teal'} margin={'1rem'} fontSize={'xl'}><ArrowBackIcon /> Go Back</Link>
    </VStack>
  );
};

export default CreateGame;
