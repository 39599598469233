'use client'
import { useEffect, useState } from 'react';
import { Box, Text, RadioGroup, Radio, Button, Stack, Heading, StackDivider, VStack, FormControl, FormLabel, Flex, Link, Checkbox, CheckboxGroup } from '@chakra-ui/react';
import { useNavigate, useParams } from 'react-router-dom';
import { ArrowBackIcon } from '@chakra-ui/icons';

const PlayerInfo = () => {
  const params = useParams()
  const navigate = useNavigate()

  const [mapUrl, setMapUrl] = useState('')
  const [currentlySelecting, setCurrentlySelecting] = useState('')
  const [choices, setChoices] = useState([])
  const [selectionCount, setSelectionCount] = useState(0)
  const [selected, setSelected] = useState('')
  const [multiSelected, setMultiSelected] = useState([])
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [faction, setFaction] = useState('')
  const [slice, setSlice] = useState('')
  const [startingTech, setStartingTech] = useState('')
  const [omenDice, setOmenDice] = useState(null)
  const [policies, setPolicies] = useState(null)
  const [oracleAiObjective, setOracleAiObjective] = useState(null)
  const [strategy, setStrategy] = useState('')
  const [secretObjective, setSecretObjective] = useState('')
  const [error, setError] = useState('')

  const playerInfoUrl = `https://api.ti4setup.markgatesman.com/player?game_code=${params.gameCode}&player_code=${params.playerCode}`

  const getPlayerInfo = () => fetch(playerInfoUrl)
    .then(async res => {
      if (!res.ok) {
        const errorMessage = await res.json()
        const errorString = errorMessage.errors ?
          `${errorMessage.errors[0].title} - ${errorMessage.errors[0].detail}` :
          JSON.stringify(errorMessage)
        setError(errorString)
        return
      }

      setError('')
      return res.json()
    })
    .then((data) => {
      if (!data) { return }

      setMapUrl(data.map_url)
      setName(data.player_data.name)
      setEmail(data.player_data.email)
      setCurrentlySelecting(data?.choice_text || '')
      setChoices(data?.choices || [])
      setSelectionCount(data?.selection_count || 0)
      setFaction(data.player_data.faction || '')
      setSlice(data.player_data.slice || '')
      setStartingTech(data.player_data.tech || '')
      setStrategy(data.player_data.strategy || '')
      setSecretObjective(data.player_data.secret || '')
      setOracleAiObjective(data.player_data.oracle_ai_objective || null)
      setOmenDice(data.player_data.omen_dice || '')
      setPolicies(data.player_data.policies || '')

      return data
    })
    .catch(err => {
      localStorage.removeItem('playerCode')
      setError(`Invalid Game Code ${params.gameCode} / Player Code ${params.playerCode}`)
      console.error(err)
    })

  useEffect(() => {
    localStorage.setItem('playerCode', params.playerCode)
    getPlayerInfo()
  }, [])

  const onSelectHandler = async () => {
    const payload = {
      choice: selected
    }
    await fetch(playerInfoUrl, {
      method: 'POST',
      body: JSON.stringify(payload)
    })
    .then(async res => {
      if (!res.ok) {
        const errorMessage = await res.json()
        const errorString = errorMessage.errors ?
          `${errorMessage.errors[0].title} - ${errorMessage.errors[0].detail}` :
          JSON.stringify(errorMessage)
        setError(errorString)
        return
      }

      setError('')
      getPlayerInfo()
    })
    .catch(error => console.error(error))
  }

  const onMultiSelectChange = (value) => {
    if (value.length <= selectionCount) { setMultiSelected(value) }
  }

  const onMultiSelectHandler = async () => {
    const payload = {
      choice: multiSelected
    }
    await fetch(playerInfoUrl, {
      method: 'POST',
      body: JSON.stringify(payload)
    })
    .then(async res => {
      if (!res.ok) {
        const errorMessage = await res.json()
        const errorString = errorMessage.errors ?
          `${errorMessage.errors[0].title} - ${errorMessage.errors[0].detail}` :
          JSON.stringify(errorMessage)
        setError(errorString)
        return
      }

      setError('')
      getPlayerInfo()
    })
    .catch(error => console.error(error))
  }

  const goToGamePageHandler = () => {
    navigate(`/game/${params.gameCode}`)
  }

  const goToPlayerPageHandler = () => {
    localStorage.removeItem('playerCode')
    navigate(`/game/${params.gameCode}/player`)
  }

  if(error) {
    return(
      <Flex align="center" justify="center" height="100vh" margin={'1rem'}>
        <Text fontSize="3xl">{error}</Text>
        <Link href={`/game/${params.gameCode}/player`} color={'teal'} margin={'1rem'} fontSize={'xl'}><ArrowBackIcon/> Go Back</Link>
      </Flex>
    )
  }

  return (
    <VStack align="center" justify="top" height="100vh" padding='1rem'>
      <Box padding='1rem'><Heading size='2xl'>{name}</Heading></Box>
      
      <Text>{email}</Text>

      <Link onClick={goToGamePageHandler} color={'teal'}>Go To Game Page</Link>
      <Link onClick={goToPlayerPageHandler} color={'teal'}>Go To Player Login</Link>
      <Link href={mapUrl} isExternal color={'blue'}>Game Map</Link>

      {choices.length > 0 && selectionCount === 1 && <Box borderWidth='1px' p='20px' borderRadius='lg' maxW='xlg' borderColor='darkgray' shadow='md' backgroundColor='white' w={['2xs', 'sm', 'md']}>
      <FormControl>
        <FormLabel>{currentlySelecting}</FormLabel>
        <VStack>
          <RadioGroup onChange={setSelected} value={selected}>
            <VStack spacing='1rem' align='left'>
            {choices.map((choice, index) => (
              <Radio key={`choice${index}`} value={choice} colorScheme='teal' borderColor='gray'>{choice}</Radio>
            ))}
            </VStack>
          </RadioGroup>
          <Button colorScheme='blue' onClick={onSelectHandler}> Select </Button>
        </VStack>
      </FormControl>
      </Box>}

      {choices.length > 0 && selectionCount > 1 && <Box borderWidth='1px' p='20px' borderRadius='lg' maxW='xlg' borderColor='darkgray' shadow='md' backgroundColor='white' w={['2xs', 'sm', 'md']}>
      <FormControl>
        <FormLabel>{currentlySelecting}</FormLabel>
        <VStack>
          <CheckboxGroup onChange={onMultiSelectChange} value={multiSelected} >
            <VStack spacing='1rem' align='left'>
              {choices.map((choice, index) => (
                <Checkbox key={`choice${index}`} value={choice} colorScheme='teal' borderColor='gray'>{choice}</Checkbox>
              ))}
            </VStack>
          </CheckboxGroup>
          <Button colorScheme='blue' onClick={onMultiSelectHandler}> Select </Button>
        </VStack>
      </FormControl>
      </Box>}

      <Heading size='xl' margin='1rem'>Selections</Heading>
      <Stack divider={<StackDivider borderColor='lightgray' />} spacing='4' width={['2xs', 'sm', 'md']} padding='1rem'>
              {faction && <Box>
          <Heading size='md' textTransform='uppercase'>
            Faction
          </Heading>
          <Text pt='2' fontSize='md'>
            {faction}
          </Text>
        </Box>}
        {slice && <Box>
          <Heading size='md' textTransform='uppercase'>
            Slice
          </Heading>
          <Text pt='2' fontSize='md'>
            {slice}
          </Text>
        </Box>}
        {startingTech && <Box>
          <Heading size='md' textTransform='uppercase'>
            Starting Tech
          </Heading>
          <Text pt='2' fontSize='md'>
            {Array.isArray(startingTech) ?
              startingTech.map((tech, index) => (
                <Text pt='2' fontSize='md' key={`tech${index}`}>{tech}</Text>
              )) : (
                <Text pt='2' fontSize='md'>{startingTech}</Text>
            )}
          </Text>
        </Box>}
        {omenDice && <Box>
          <Heading size='md' textTransform='uppercase'>
            Omen Dice
          </Heading>
          <Text pt='2' fontSize='md'>
            {omenDice.join(', ')}
          </Text>
        </Box>}
        {policies && <Box>
          <Heading size='md' textTransform='uppercase'>
            Policies
          </Heading>
          {policies.map((policy, index) => (
            <Text pt='2' fontSize='md' key={`policy${index}`}>{policy}</Text>
          ))}
        </Box>}
        {oracleAiObjective && <Box>
          <Heading size='md' textTransform='uppercase'>
            Oracle AI Objective
          </Heading>
          <Text pt='2' fontSize='md'>
            {oracleAiObjective.name}:
          </Text>
          <Text pt='2' fontSize='md'>
            {oracleAiObjective.description}
          </Text>
        </Box>}
        {strategy && <Box>
          <Heading size='md' textTransform='uppercase'>
            Strategy
          </Heading>
          <Text pt='2' fontSize='md'>
            {strategy}
          </Text>
        </Box>}
        {secretObjective && <Box>
          <Heading size='md' textTransform='uppercase'>
            Secret Objective
          </Heading>
          <Text pt='2' fontSize='md'>
            {secretObjective}
          </Text>
        </Box>}
      </Stack>
    </VStack>
  );
};

export default PlayerInfo;
