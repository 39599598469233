export const FACTIONS_BY_SET = {
  baseGameFactions: [
    "The Arborec",
    "The Barony of Letnev",
    "The Clan of Saar",
    "The Embers of Muaat",
    "The Emirates of Hacan",
    "Federation of Sol",
    "The Ghosts of Creuss",
    "The L1Z1X Mindnet",
    "The Mentak Coalition",
    "The Naalu Collective",
    "The Nekro Virus",
    "Sardakk N'orr",
    "The Universities of Jol-Nar",
    "The Winnu",
    "The Xxcha Kingdom",
    "Yin Brotherhood",
    "The Yssaril Tribes"
  ],
  prophecyOfKingsFactions: [
    "The Argent Flight",
    "The Empyrean",
    "The Mahact Gene-Sorcerers",
    "The Naaz-Rokha Alliance",
    "The Nomad",
    "The Titans of Ul",
    "The Vuil'Raith Cabal"
  ],
  codexV3Faction: [
    "The Council of Keleres"
  ],
  discordantStarsFactions: [
    "The Shipwrights of Axis",
    "The Bentor Conglomerate",
    "The Celdauri Trade Confederation",
    "The Cheiran Hordes",
    "The Savages of Cymiae",
    "The Dih-Mohn Flotilla",
    "The Edyn Mandate",
    "The Florzen Profiteers",
    "The Free Systems Compact",
    "The Ghemina Raiders",
    "The Ghoti Wayfarers",
    "The GLEdge Union",
    "The Augurs of Ilyxum",
    "The Berserkers of Kjalengard",
    "The Kollecc Society",
    "The Monks of Kolume",
    "The Kortali Tribunal",
    "The Kyro Sodality",
    "The Lanefir Remnants",
    "The Li-Zho Dynasty",
    "The L'tokk Khrask",
    "The Mirveda Protectorate",
    "The Glimmer of Mortheus",
    "The Myko-Mentori",
    "The Nivyn Star Kings",
    "The Nokar Sellships",
    "The Olradin League",
    "The Zealots of Rhodun",
    "Roh'Dhna Mechatronics",
    "The Tnelis Syndicate",
    "The Vaden Banking Clans",
    "The Vaylerian Scourge",
    "The Veldyr Sovereignty",
    "The Zelian Purifier"
  ]
}